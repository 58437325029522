@import "../../global.scss";

.contact {
  background-color: orange;
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  .left {
    flex: 1;
    overflow: hidden;

    img {
      height: 100%;
    }
  }
  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
      font-size: 3vw;

      @include mobile {
        font-size: 9vw;
      }
    }

    p {
      font-size: 2.5vw;

      @include mobile {
        font-size: 6vw;
      }
    }

  }
}