@import "../../global.scss";

.works {
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 112vh !important;

  @include mobile {
    height: 550vh !important;
  }
  
  h1 {
    position: absolute;
    top: 0%;
    font-size: 50px;
    margin-top: 9vh;

    @include mobile {
      margin-top: 5.02vh;
    }
  }

  #img-name {
    text-align: center;
    position: relative;
    
  }

  #work-image {
    width: auto;
    height: 75vh;

    @include mobile {
      height: 40.65vh;
      // border-radius: 5%;
    }
  }

  .arrow {
    height: 50px;
    position: absolute;
    cursor: pointer;

    @include mobile {
      display: none;
    }

    &.left {
      left: 100px;
      transform: rotateY(180deg);
    }

    &.right {
      right: 100px;
    }
  }

  .slider {
    height: 75vh;
    display: flex;
    position: absolute;
    left: 0;
    transition: all 1s ease-out;
    margin-top: 8vh;
    margin-bottom: 7vh;

    @include mobile {
      height: auto;
      flex-direction: column;
      justify-content: center;
    }

    .container {
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 75vh;
      background: orange;

      @include mobile {
        height: 40.65vh;
        margin-bottom: 5.3vh;
      }

      .item {
        width: auto;
        height: 75vh;
        background-color: orange;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        

        @include mobile {
          width: 95%;
          height: 40.65vh;
          position: absolute;
          margin: 15px 0;
          background-color: orange;
        }

        .left {
          flex: 4;
          height: 80%;
          display: flex;
          align-items: center;
          justify-content: center;

          .leftContainer {
            width: 90%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .imgContainer {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-color: rgb(245, 179, 155);
              display: flex;
              align-items: center;
              justify-content: center;

              @include mobile {
                width: 20px;
                height: 20px;
              }

              img {
                width: 25px;

                @include mobile {
                  width: 15px;
                }
              }
            }
            h2 {
              font-size: 20px;

              @include mobile{
                font-size: 13px;
              }
            }
            p {
              font-size: 13px;
              @include mobile{
                display: none;
              }
            }
            span {
              font-size: 12px;
              font-weight: 600;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
        .right {
          flex: 8;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          img {
            width: 400px;
            transform: rotate(-10deg);
          }
        }
      }
    }
  }
}
