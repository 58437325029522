@import "../../global.scss";

.topbar {
  width: 100%;
  height: auto;
  background-color: orange;
  color: $mainColor;
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;

  @include mobile {
    height: 150px;
  }

  #topbar-contacts {
    display: none;

    @include mobile {
      display: grid;

      #contact-phone-mobile {
        margin-left: 27vw;
        font-size: 6vw;
      }

      #contact-email-mobile {
        font-size: 4vw;
        margin-left: 3vw;
      }
      
    }
  }

  .wrapper {
    padding: 10px 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

    #logo-img {
      height: 110px;
      width: 90px;
      margin-right: 30px;

      @include mobile {
        height: 67px;
        width: 57px;
        margin-right: 10px;
      }
    }

      .logo {
        font-size: 3.2vw;
        font-weight: 700;
        text-decoration: none;
        color: black;

        @include mobile {
          font-size: 33px;
          font-weight: 700;
        }
        
      }

      .itemContainer {
        display: flex;
        align-items: center;
        margin-left: 30px;
        color: solid black;

        @include mobile{
          display: none;
        }

        .icon {
          font-size: 2vw;
          margin-right: 5px;
          font-weight: bold;
        }

        span {
          font-size: 1.5vw;
          font-weight: 500;
        }
      }
    }

    .right {
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          background-color: $mainColor;
          transform-origin: left;
          transition: all 2s ease;
        }
      }
    }
  }

  &.active {
    background-color: $mainColor;
    color: white;

    .hamburger {
      span {
        &:first-child {
          background-color: white;
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          background-color: white;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
