@import "../../global.scss";

.prices {
  background-color: #800020;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto !important;

  @include mobile{
    justify-content: space-around;
  }

  #price-table {
    box-sizing: inherit;
      border-collapse: collapse;
      width: 60%;
      table-layout: auto;
      font-size: 30px;
      margin-bottom: 20px;
  }

  th {
    text-align: left;
    border-bottom: 1px solid black;
  }

  td {
    text-align: left;
    border-bottom: 1px solid black;
  }

  h1 {
    font-size: 50px;
    margin-top: 10vh;
    margin-bottom: 25px;

    @include mobile{
      font-size: 40px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }

  .container {
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile{
      flex-direction: column;
      height: 100%;
    }
  }
}
