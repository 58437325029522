@import "../../global.scss";

.intro {
  background-color: #800020;
  display: flex;
  height: auto !important;

  @include mobile{
    flex-direction: column;
    align-items: center;
    margin-top: 7vh;
  }

  .left {
    flex: 0.25;
    overflow: hidden;

    .imgContainer {
      width: 20vw;
      height: 38vh;
      margin-top: 16vh;
      background-color: orange;
      border-radius: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: left;
      float: right;

      @include mobile{
        align-items: flex-start;
        width: 290px;
        height: 290px;
        margin-top: 45px;
      }

      img {
        height: 90%;

        @include mobile{
          height: 90%;
        }
      }
    }
  }
  .right {
    flex: 0.7;
    position: relative;
    justify-content: flex-end;

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile{
        padding-left: 0;
        align-items: center;
        display:block;
        margin-top:-58px;
      }

      h1 {
        font-size: 5vw;
        margin-top: 13vh;
        text-align: center;
        font-weight: 800;

        @include mobile{
          font-size: 40px;
          text-align: center;
        }
      }

      h2 {
        text-align: center;
        font-size: 3vw;
        margin-top: 5vh;
        margin-bottom: 5vh;

        @include mobile {
          font-size: 6vw;
        }
      }

      #intro-list {
        font-size: 20px;
        margin-right: 50px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 20px;

        @include mobile{
          font-size: 20px;
          text-align: center;
          margin-left: 35px;
        }
      }

      #list-headline {
        font-size: 40px;
        font-weight: 800;
        margin-top: 55px;

        @include mobile {
          font-size: 20px;
          text-align: center;
        }
      }

      h3 {
        font-size: 20px;
        margin-right: 50px;
        text-align: center;

        @include mobile{
          font-size: 20px;
          text-align: center;
          margin-left: 35px;
        }

        span {
          font-size: inherit;
          color: crimson;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
            50%{
                opacity: 1;
            }
            100%{
                opacity: 0;
            }
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 40%;

      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}